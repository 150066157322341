// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, useLocation } from 'react-router-dom';
// // import axios from 'axios';
// // import './EventForm.css';
// // import moment from 'moment-timezone';


// // function EventForm() {
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   const userEmail = location.state?.userEmail;

// //   const [formData, setFormData] = useState({
// //     eventName: '',
// //     eventDate: '',
// //     eventCategory: 'Exhibition',
// //     marketingBudget: '',
// //     averageOrderValue: '',
// //     eventObjective: 'Visitors',
// //     targetRevenue: '',
// //     averageTicketPrice: '',
// //     eventLocation: '',
// //     companyName: '',
// //     hitTarget: false,
// //     timezone: '',
// //     currency: 'USD',
// //     platforms: {
// //       offline: [],
// //       online: []
// //     }
// //   });

// //   const offlinePlatforms = ["Posters", "Radio", "Flyers", "Print_Ads", "Bus_Sides", "Taxi_backs", "Direct_Mail", "PR", "Billboard", "Newspaper_ad"];
// //   const onlinePlatforms = ['Tiktok','Facebook','Instagram','Linkedin','Google_Demand_Gen','Google_Pmax','Google_Search','SMS', 'Referrals', 'Partner_Digital', 'Internal_EDMS'];

// //   useEffect(() => {
// //     const fetchEventData = async (eventName) => {
// //       try {
// //         const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_details/${eventName}`);
// //         const data = response.data[0];
        
// //         const eventDate = new Date(data.event_date);
// //         const formattedDate = eventDate.toISOString().split('T')[0];
        
// //         setFormData({
// //           eventName: data.event_name,
// //           eventDate: formattedDate,
// //           eventCategory: data.event_category || 'Exhibition',
// //           marketingBudget: data.marketing_budget || '',
// //           averageOrderValue: data.average_order_value || '',
// //           eventObjective: data.event_objective || 'Visitors',
// //           targetRevenue: data.target_revenue || '',
// //           averageTicketPrice: data.average_ticket_price || '',
// //           eventLocation: data.event_location || '',
// //           companyName: data.company_name || '',
// //           hitTarget: data.hit_target || false,
// //           timezone: data.timezone || '',
// //           currency: data.currency || 'USD',
// //           platforms: {
// //             offline: data.offline_platforms ? data.offline_platforms.replace(/{|}/g, '').split(',') : [],
// //             online: data.online_platforms ? data.online_platforms.replace(/{|}/g, '').split(',') : []
// //           }
// //         });
// //       } catch (error) {
// //         console.error('Error fetching event data:', error);
// //       }
// //     };
  
// //     const eventName = location.state?.eventName;
  
// //     if (eventName) {
// //       fetchEventData(eventName);
// //     } else {
// //       const savedFormData = localStorage.getItem('eventFormData');
// //       if (savedFormData) {
// //         setFormData(JSON.parse(savedFormData));
// //       }
// //     }
// //   }, [location.state, userEmail]);
  

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData(prevData => ({ ...prevData, [name]: value }));
// //   };

// //   const handleCheckboxChange = (e) => {
// //     const { name, checked } = e.target;
// //     setFormData(prevData => ({ ...prevData, [name]: checked }));
// //   };

// //   const handlePlatformChange = (type, platform) => {
// //     setFormData(prevData => {
// //       const updatedPlatforms = { ...prevData.platforms };
// //       if (updatedPlatforms[type].includes(platform)) {
// //         updatedPlatforms[type] = updatedPlatforms[type].filter(p => p !== platform);
// //       } else {
// //         updatedPlatforms[type] = [...updatedPlatforms[type], platform];
// //       }
// //       return { ...prevData, platforms: updatedPlatforms };
// //     });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       let response;
// //       const dataToSend = { ...formData, userEmail };
// //       if (formData.id) {
// //         // If the form data has an id, it's an update operation
// //         response = await axios.put(`https://budget-tool-hed-1.onrender.com/update_event/${formData.id}`, dataToSend);
// //         console.log('Event updated:', response.data);
// //       } else {
// //         // If there's no id, it's a create operation
// //         response = await axios.post('https://budget-tool-hed-1.onrender.com/create_event', dataToSend);
// //         console.log('Event created:', response.data);
// //       }
// //       localStorage.setItem('eventFormData', JSON.stringify(formData));
// //       navigate('/eventtable', { state: { eventName: formData.eventName, userEmail } });
// //     } catch (error) {
// //       console.error('Error saving event:', error);
// //       if (error.response && error.response.data && error.response.data.detail) {
// //         if (error.response.data.detail.includes("Key (event_name)=") && error.response.data.detail.includes("already exists")) {
// //           setErrorMessage(<span className="error-text">An event with this name already exists. Please choose a different name.</span>);
// //         } else {
// //           setErrorMessage(<span className="error-text">An error occurred while saving the event. Please try again.</span>);
// //         }
// //       } else {
// //         setErrorMessage(<span className="error-text">Event name should be unique.</span>);
// //       }
// //     }
// //   };

// //   const timezones = moment.tz.names();

// //   // Group time zones by continent/region
// //   const groupedTimezones = timezones.reduce((acc, tz) => {
// //     const [continent, ...rest] = tz.split('/');
// //     if (!acc[continent]) {
// //       acc[continent] = [];
// //     }
// //     acc[continent].push(tz);
// //     return acc;
// //   }, {});

// //   return (
// //     <div className="event-form-container">
// //       <header>
// //         {/* <h1><span>Event Lists</span> &gt; <span>Create New Event</span></h1> */}
// //       </header>
// //       <main>
// //         <form onSubmit={handleSubmit}>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="eventName">Event Name *</label>
// //               <input
// //                 type="text"
// //                 id="eventName"
// //                 name="eventName"
// //                 value={formData.eventName}
// //                 onChange={handleInputChange}
// //                 required
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="eventDate">Event Date *</label>
// //               <input
// //                 type="date"
// //                 id="eventDate"
// //                 name="eventDate"
// //                 value={formData.eventDate}
// //                 onChange={handleInputChange}
// //                 required
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="eventCategory">Event Category *</label>
// //               <select
// //                 id="eventCategory"
// //                 name="eventCategory"
// //                 value={formData.eventCategory}
// //                 onChange={handleInputChange}
// //                 required
// //               >
// //                 <option value="Trade Shows and Exhibitions">Trade Shows and Exhibitions</option>
// //                 <option value="Art and Cultural Events">Art and Cultural Events</option>
// //                 <option value="Non-Profit Event">Non-Profit Event</option>
// //                 <option value="Concert and Music">Concert and Music</option>
// //                 <option value="Fundraisers">Fundraisers</option>
// //                 <option value="Networking">Networking</option>
// //               </select>
// //             </div>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="marketingBudget">Total Marketing budget (Excluding GST) *</label>
// //               <input
// //                 type="number"
// //                 id="marketingBudget"
// //                 name="marketingBudget"
// //                 value={formData.marketingBudget}
// //                 onChange={handleInputChange}
// //                 required
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="averageOrderValue">Average Order Value</label>
// //               <input
// //                 type="number"
// //                 id="averageOrderValue"
// //                 name="averageOrderValue"
// //                 value={formData.averageOrderValue}
// //                 onChange={handleInputChange}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="eventObjective">Event Objective *</label>
// //               <select
// //                 id="eventObjective"
// //                 name="eventObjective"
// //                 value={formData.eventObjective}
// //                 onChange={handleInputChange}
// //                 required
// //               >
// //                 <option value="Visitors">Visitors</option>
// //                 <option value="Ticket sales">Ticket sales</option>
// //                 {/* <option value="Maximise ROI">Maximise ROI</option> */}
// //               </select>
// //             </div>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="targetRevenue">Target Revenue from Ticket sales</label>
// //               <input
// //                 type="number"
// //                 id="targetRevenue"
// //                 name="targetRevenue"
// //                 value={formData.targetRevenue}
// //                 onChange={handleInputChange}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="averageTicketPrice">Average Ticket Price *</label>
// //               <input
// //                 type="number"
// //                 id="averageTicketPrice"
// //                 name="averageTicketPrice"
// //                 value={formData.averageTicketPrice}
// //                 onChange={handleInputChange}
// //                 required
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="eventLocation">Event Location *</label>
// //               <input
// //                 type="text"
// //                 id="eventLocation"
// //                 name="eventLocation"
// //                 value={formData.eventLocation}
// //                 onChange={handleInputChange}
// //                 required
// //               />
// //             </div>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="companyName">Company Name</label>
// //               <input
// //                 type="text"
// //                 id="companyName"
// //                 name="companyName"
// //                 value={formData.companyName}
// //                 onChange={handleInputChange}
// //               />
// //             </div>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label>Did you hit the target?</label>
// //               <div className="toggle-switch">
// //                 <input
// //                   type="checkbox"
// //                   id="hitTarget"
// //                   name="hitTarget"
// //                   checked={formData.hitTarget}
// //                   onChange={handleCheckboxChange}
// //                 />
// //                 <label htmlFor="hitTarget">
// //                   <span className="toggle-label">Yes</span>
// //                   <span className="toggle-label">No</span>
// //                 </label>
// //               </div>
// //             </div>
// //              <div className="form-group">
// //               <label htmlFor="timezone">Set Timezone *</label>
// //               <select
// //                 id="timezone"
// //                 name="timezone"
// //                 value={formData.timezone}
// //                 onChange={handleInputChange}
// //                 required
// //               >
// //                 <option value="">Select a timezone</option>
// //                 {Object.entries(groupedTimezones).map(([continent, zones]) => (
// //                   <optgroup key={continent} label={continent}>
// //                     {zones.map((tz) => (
// //                       <option key={tz} value={tz}>
// //                         {tz} ({moment.tz(tz).format('Z')})
// //                       </option>
// //                     ))}
// //                   </optgroup>
// //                 ))}
// //               </select>
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="currency">Set Currency *</label>
// //               <select
// //                 id="currency"
// //                 name="currency"
// //                 value={formData.currency}
// //                 onChange={handleInputChange}
// //                 required
// //               >
// //                 <option value="USD">USD</option>
// //                 <option value="AUD">AUD</option>
// //                 <option value="GBP">GBP</option>
// //                 <option value="Euro">Euro</option>
// //               </select>
// //             </div>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group full-width">
// //               <label>Platforms</label>
// //               <div className="platforms-container">
// //                 <div className="platform-group">
// //                   <h4>Offline</h4>
// //                   <div className="platform-options">
// //                     {offlinePlatforms.map((platform) => (
// //                       <button
// //                         key={platform}
// //                         type="button"
// //                         className={formData.platforms.offline.includes(platform) ? 'active' : ''}
// //                         onClick={() => handlePlatformChange('offline', platform)}
// //                       >
// //                         {platform}
// //                       </button>
// //                     ))}
// //                   </div>
// //                 </div>
// //                 <div className="platform-group">
// //                   <h4>Online</h4>
// //                   <div className="platform-options">
// //                     {onlinePlatforms.map((platform) => (
// //                       <button
// //                         key={platform}
// //                         type="button"
// //                         className={formData.platforms.online.includes(platform) ? 'active' : ''}
// //                         onClick={() => handlePlatformChange('online', platform)}
// //                       >
// //                         {platform}
// //                       </button>
// //                     ))}
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="form-actions">
// //             <button type="submit" className="btn-next">Next</button>
// //           </div>
// //         </form>
// //       </main>
// //     </div>
// //   );
// // };

// // export default EventForm;

// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import axios from 'axios';
// import './EventForm.css';
// import moment from 'moment-timezone';

// function EventForm() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const userEmail = location.state?.userEmail;

//   const [formData, setFormData] = useState({
//     eventName: '',
//     eventDate: '',
//     eventCategory: 'Exhibition',
//     marketingBudget: '',
//     averageOrderValue: '',
//     eventObjective: 'Visitors',
//     targetRevenue: '',
//     averageTicketPrice: '',
//     eventLocation: '',
//     companyName: '',
//     hitTarget: false,
//     timezone: '',
//     currency: 'USD',
//     platforms: {
//       offline: [],
//       online: []
//     }
//   });

//   const [errorMessage, setErrorMessage] = useState('');

//   const offlinePlatforms = ["Posters", "Radio", "Flyers", "Print_Ads", "Bus_Sides", "Taxi_backs", "Direct_Mail", "PR", "Billboard", "Newspaper_ad"];
//   const onlinePlatforms = ['Tiktok','Facebook','Instagram','Linkedin','Google_Demand_Gen','Google_Pmax','Google_Search','SMS', 'Referrals', 'Partner_Digital', 'Internal_EDMS'];

//   useEffect(() => {
//     const fetchEventData = async (eventName) => {
//       try {
//         const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_details/${eventName}`);
//         const data = response.data[0];
        
//         const eventDate = new Date(data.event_date);
//         const formattedDate = eventDate.toISOString().split('T')[0];
        
//         setFormData({
//           eventName: data.event_name,
//           eventDate: formattedDate,
//           eventCategory: data.event_category || 'Exhibition',
//           marketingBudget: data.marketing_budget || '',
//           averageOrderValue: data.average_order_value || '',
//           eventObjective: data.event_objective || 'Visitors',
//           targetRevenue: data.target_revenue || '',
//           averageTicketPrice: data.average_ticket_price || '',
//           eventLocation: data.event_location || '',
//           companyName: data.company_name || '',
//           hitTarget: data.hit_target || false,
//           timezone: data.timezone || '',
//           currency: data.currency || 'USD',
//           platforms: {
//             offline: data.offline_platforms ? data.offline_platforms.replace(/{|}/g, '').split(',') : [],
//             online: data.online_platforms ? data.online_platforms.replace(/{|}/g, '').split(',') : []
//           }
//         });
//       } catch (error) {
//         console.error('Error fetching event data:', error);
//         setErrorMessage('Error fetching event data. Please try again.');
//       }
//     };
  
//     const eventName = location.state?.eventName;
  
//     if (eventName) {
//       fetchEventData(eventName);
//     } else {
//       const savedFormData = localStorage.getItem('eventFormData');
//       if (savedFormData) {
//         setFormData(JSON.parse(savedFormData));
//       }
//     }
//   }, [location.state, userEmail]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevData => ({ ...prevData, [name]: value }));
//   };

//   const handleCheckboxChange = (e) => {
//     const { name, checked } = e.target;
//     setFormData(prevData => ({ ...prevData, [name]: checked }));
//   };

//   const handlePlatformChange = (type, platform) => {
//     setFormData(prevData => {
//       const updatedPlatforms = { ...prevData.platforms };
//       if (updatedPlatforms[type].includes(platform)) {
//         updatedPlatforms[type] = updatedPlatforms[type].filter(p => p !== platform);
//       } else {
//         updatedPlatforms[type] = [...updatedPlatforms[type], platform];
//       }
//       return { ...prevData, platforms: updatedPlatforms };
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setErrorMessage(''); // Clear any previous error messages
//     try {
//       let response;
//       const dataToSend = { ...formData, userEmail };
  
//       if (location.state?.eventName) {
//         // If eventName is in location.state, it's an update operation
//         response = await axios.post(`https://budget-tool-hed-1.onrender.com/update_event/${formData.eventName}`, dataToSend);
//         console.log('Event updated:', response.data);
//       } else {
//         // If there's no eventName in location.state, it's a create operation
//         response = await axios.post('https://budget-tool-hed-1.onrender.com/create_event', dataToSend);
//         console.log('Event created:', response.data);
//       }
  
//       localStorage.setItem('eventFormData', JSON.stringify(formData));
//       navigate('/eventtable', { state: { eventName: formData.eventName, userEmail } });
//     } catch (error) {
//         setErrorMessage(<span className="error-text">Event Name already exist.</span>);
//         // console.error('Error saving event:', error);
//     }
//     };

//   const timezones = moment.tz.names();

//   // Group time zones by continent/region
//   const groupedTimezones = timezones.reduce((acc, tz) => {
//     const [continent, ...rest] = tz.split('/');
//     if (!acc[continent]) {
//       acc[continent] = [];
//     }
//     acc[continent].push(tz);
//     return acc;
//   }, {});

//   return (
//     <div className="event-form-container">
//       <header>
//         {/* <h1><span>Event Lists</span> &gt; <span>Create New Event</span></h1> */}
//       </header>
//       <main>
//         {errorMessage && <div className="error-message">{errorMessage}</div>}
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="eventName">Event Name *</label>
//               <input
//                 type="text"
//                 id="eventName"
//                 name="eventName"
//                 value={formData.eventName}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="eventDate">Event Date *</label>
//               <input
//                 type="date"
//                 id="eventDate"
//                 name="eventDate"
//                 value={formData.eventDate}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="eventCategory">Event Category *</label>
//               <select
//                 id="eventCategory"
//                 name="eventCategory"
//                 value={formData.eventCategory}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="Trade Shows and Exhibitions">Trade Shows and Exhibitions</option>
//                 <option value="Art and Cultural Events">Art and Cultural Events</option>
//                 <option value="Non-Profit Event">Non-Profit Event</option>
//                 <option value="Concert and Music">Concert and Music</option>
//                 <option value="Fundraisers">Fundraisers</option>
//                 <option value="Networking">Networking</option>
//               </select>
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="marketingBudget">Total Marketing budget (Excluding GST) *</label>
//               <input
//                 type="number"
//                 id="marketingBudget"
//                 name="marketingBudget"
//                 value={formData.marketingBudget}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="averageOrderValue">Average Order Value</label>
//               <input
//                 type="number"
//                 id="averageOrderValue"
//                 name="averageOrderValue"
//                 value={formData.averageOrderValue}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="eventObjective">Event Objective *</label>
//               <select
//                 id="eventObjective"
//                 name="eventObjective"
//                 value={formData.eventObjective}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="Visitors">Visitors</option>
//                 <option value="Ticket Sales">Ticket Sales</option>
//                 {/* <option value="Maximise ROI">Maximise ROI</option> */}
//               </select>
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="targetRevenue">Target Revenue from Ticket sales</label>
//               <input
//                 type="number"
//                 id="targetRevenue"
//                 name="targetRevenue"
//                 value={formData.targetRevenue}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="averageTicketPrice">Average Ticket Price *</label>
//               <input
//                 type="number"
//                 id="averageTicketPrice"
//                 name="averageTicketPrice"
//                 value={formData.averageTicketPrice}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="eventLocation">Event Location *</label>
//               <input
//                 type="text"
//                 id="eventLocation"
//                 name="eventLocation"
//                 value={formData.eventLocation}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="companyName">Company Name</label>
//               <input
//                 type="text"
//                 id="companyName"
//                 name="companyName"
//                 value={formData.companyName}
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label>Did you hit the target?</label>
//               <div className="toggle-switch">
//                 <input
//                   type="checkbox"
//                   id="hitTarget"
//                   name="hitTarget"
//                   checked={formData.hitTarget}
//                   onChange={handleCheckboxChange}
//                 />
//                 <label htmlFor="hitTarget">
//                   <span className="toggle-label">Yes</span>
//                   <span className="toggle-label">No</span>
//                 </label>
//               </div>
//             </div>
//              <div className="form-group">
//               <label htmlFor="timezone">Set Timezone *</label>
//               <select
//                 id="timezone"
//                 name="timezone"
//                 value={formData.timezone}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="">Select a timezone</option>
//                 {Object.entries(groupedTimezones).map(([continent, zones]) => (
//                   <optgroup key={continent} label={continent}>
//                     {zones.map((tz) => (
//                       <option key={tz} value={tz}>
//                         {tz} ({moment.tz(tz).format('Z')})
//                       </option>
//                     ))}
//                   </optgroup>
//                 ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label htmlFor="currency">Set Currency *</label>
//               <select
//                 id="currency"
//                 name="currency"
//                 value={formData.currency}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="USD">USD</option>
//                 <option value="AUD">AUD</option>
//                 <option value="GBP">GBP</option>
//                 <option value="Euro">Euro</option>
//               </select>
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group full-width">
//               <label>Platforms</label>
//               <div className="platforms-container">
//                 <div className="platform-group">
//                   <h4>Offline</h4>
//                   <div className="platform-options">
//                     {offlinePlatforms.map((platform) => (
//                       <button
//                         key={platform}
//                         type="button"
//                         className={formData.platforms.offline.includes(platform) ? 'active' : ''}
//                         onClick={() => handlePlatformChange('offline', platform)}
//                       >
//                         {platform}
//                       </button>
//                     ))}
//                   </div>
//                 </div>
//                 <div className="platform-group">
//                   <h4>Online</h4>
//                   <div className="platform-options">
//                     {onlinePlatforms.map((platform) => (
//                       <button
//                         key={platform}
//                         type="button"
//                         className={formData.platforms.online.includes(platform) ? 'active' : ''}
//                         onClick={() => handlePlatformChange('online', platform)}
//                       >
//                         {platform}
//                       </button>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="form-actions">
//             <button type="submit" className="btn-next">Next</button>
//           </div>
//         </form>
//       </main>
//     </div>
//   );
// }

// export default EventForm;


import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './EventForm.css';
import moment from 'moment-timezone';

function EventForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.userEmail;

  const [formData, setFormData] = useState({
    eventName: '',
    eventDate: '',
    eventCategory: '',
    eventSubCategory: '',
    marketingBudget: '',
    averageOrderValue: '',
    eventObjective: 'Visitors',
    targetRevenue: '',
    averageTicketPrice: '',
    eventLocation: '',
    companyName: '',
    hitTarget: false,
    timezone: '',
    currency: 'USD',
    platforms: {
      offline: [],
      online: []
    }
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [showSubCategories, setShowSubCategories] = useState(false);


  const offlinePlatforms = ['Posters', 'Radio', 'Flyers', 'Print_Ads', 'Bus_Sides', 'Taxi_Backs', 'Direct_Mail', 'PR', 'Billboard', 'Newspaper_Ad','TV','Newspapers','Flags'];
  const onlinePlatforms = ['Tiktok','Facebook','Instagram','Linkedin','Google_Demand_Gen','Google_Pmax','Google_Search','SMS', 'Referrals', 'Partner_Digital', 'Internal_EDMS','Bing_Search','Influencers'];

  const eventCategories = {
    B2B: ['Trade Shows', 'Conferences', 'Networking Events', 'Seminars', 'Workshops'],
    B2C: ['Exhibitions', 'Concerts', 'Festivals', 'Sports Events', 'Cultural Events']
  };

  useEffect(() => {
    const fetchEventData = async (eventName) => {
      try {
        const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_details/${eventName}`);
        const data = response.data[0];
        
        const eventDate = new Date(data.event_date);
        const formattedDate = eventDate.toISOString().split('T')[0];
        
        setFormData({
          eventName: data.event_name,
          eventDate: formattedDate,
          eventCategory: data.event_category || '',
          eventSubCategory: data.event_sub_category || '',
          marketingBudget: data.marketing_budget || '',
          averageOrderValue: data.average_order_value || '',
          eventObjective: data.event_objective || 'Visitors',
          targetRevenue: data.target_revenue || '',
          eventLocation: data.event_location || '',
          companyName: data.company_name || '',
          hitTarget: data.hit_target || false,
          timezone: data.timezone || '',
          currency: data.currency || 'USD',
          platforms: {
            offline: data.offline_platforms ? data.offline_platforms.replace(/{|}/g, '').split(',') : [],
            online: data.online_platforms ? data.online_platforms.replace(/{|}/g, '').split(',') : []
          }
        });
      } catch (error) {
        console.error('Error fetching event data:', error);
        setErrorMessage('Error fetching event data. Please try again.');
      }
    };
  
    const eventName = location.state?.eventName;
  
    if (eventName) {
      fetchEventData(eventName);
    } else {
      const savedFormData = localStorage.getItem('eventFormData');
      if (savedFormData) {
        setFormData(JSON.parse(savedFormData));
      }
    }
  }, [location.state, userEmail]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({ 
      ...prevData, 
      eventCategory: value,
      eventSubCategory: '' // Reset sub-category when main category changes
    }));
  };

  const handleSubCategoryChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({ ...prevData, eventSubCategory: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: checked }));
  };

  const handlePlatformChange = (type, platform) => {
    setFormData(prevData => {
      const updatedPlatforms = { ...prevData.platforms };
      if (updatedPlatforms[type].includes(platform)) {
        updatedPlatforms[type] = updatedPlatforms[type].filter(p => p !== platform);
      } else {
        updatedPlatforms[type] = [...updatedPlatforms[type], platform];
      }
      return { ...prevData, platforms: updatedPlatforms };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      let response;
      const dataToSend = { ...formData, userEmail };
  
      if (location.state?.eventName) {
        response = await axios.post(`https://budget-tool-hed-1.onrender.com/update_event/${formData.eventName}`, dataToSend);
        console.log('Event updated:', response.data);
      } else {
        response = await axios.post('https://budget-tool-hed-1.onrender.com/create_event', dataToSend);
        console.log('Event created:', response.data);
      }
  
      localStorage.setItem('eventFormData', JSON.stringify(formData));
      navigate('/eventtable', { state: { eventName: formData.eventName, userEmail } });
    } catch (error) {
      setErrorMessage(<span className="error-text">Event Name already exists.</span>);
    }
  };

  const timezones = moment.tz.names();

  const groupedTimezones = timezones.reduce((acc, tz) => {
    const [continent, ...rest] = tz.split('/');
    if (!acc[continent]) {
      acc[continent] = [];
    }
    acc[continent].push(tz);
    return acc;
  }, {});

  const formatPlatformName = (name) => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  };

  return (
    <div className="event-form-container">
      <header>
        {/* <h1><span>Event Lists</span> &gt; <span>Create New Event</span></h1> */}
      </header>
      <main>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="eventName">Event Name *</label>
              <input
                type="text"
                id="eventName"
                name="eventName"
                value={formData.eventName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventDate">Event Date *</label>
              <input
                type="date"
                id="eventDate"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventCategory">Event Category *</label>
              <select
                id="eventCategory"
                name="eventCategory"
                value={formData.eventCategory}
                onChange={handleCategoryChange}
                required
              >
                <option value="">Select Category</option>
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="eventSubCategory">Event Sub-Category *</label>
              <select
                id="eventSubCategory"
                name="eventSubCategory"
                value={formData.eventSubCategory}
                onChange={handleSubCategoryChange}
                required
                disabled={!formData.eventCategory}
              >
                <option value="">Select Sub-Category</option>
                {formData.eventCategory && eventCategories[formData.eventCategory]?.map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="marketingBudget">Total Marketing budget (Excluding GST) *</label>
              <input
                type="number"
                id="marketingBudget"
                name="marketingBudget"
                value={formData.marketingBudget}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="averageOrderValue">Average Order Value</label>
              <input
                type="number"
                id="averageOrderValue"
                name="averageOrderValue"
                value={formData.averageOrderValue}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventObjective">Event Objective *</label>
              <select
                id="eventObjective"
                name="eventObjective"
                value={formData.eventObjective}
                onChange={handleInputChange}
                required
              >
                <option value="Visitors">Visitors</option>
                <option value="Ticket Sales">Ticket Sales</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="targetRevenue">Target Revenue from Ticket sales*</label>
              <input
                type="number"
                id="targetRevenue"
                name="targetRevenue"
                value={formData.targetRevenue}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventLocation">Event Location *</label>
              <input
                type="text"
                id="eventLocation"
                name="eventLocation"
                value={formData.eventLocation}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Did you hit the target?</label>
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  id="hitTarget"
                  name="hitTarget"
                  checked={formData.hitTarget}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="hitTarget">
                  <span className="toggle-label">Yes</span>
                  <span className="toggle-label">No</span>
                </label>
              </div>
            </div>
             <div className="form-group">
              <label htmlFor="timezone">Set Timezone *</label>
              <select
                id="timezone"
                name="timezone"
                value={formData.timezone}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a timezone</option>
                {Object.entries(groupedTimezones).map(([continent, zones]) => (
                  <optgroup key={continent} label={continent}>
                    {zones.map((tz) => (
                      <option key={tz} value={tz}>
                        {tz} ({moment.tz(tz).format('Z')})
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="currency">Set Currency *</label>
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                required
              >
                <option value="USD">USD</option>
                <option value="AUD">AUD</option>
                <option value="GBP">GBP</option>
                <option value="Euro">Euro</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width">
              <label>Platforms</label>
              <div className="platforms-container">
                <div className="platform-group">
                  <h4>Offline</h4>
                  <div className="platform-options">
                    {offlinePlatforms.map((platform) => (
                      <button
                        key={platform}
                        type="button"
                        className={formData.platforms.offline.includes(platform) ? 'active' : ''}
                        onClick={() => handlePlatformChange('offline', platform)}
                      >
                        {formatPlatformName(platform)}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="platform-group">
                  <h4>Online</h4>
                  <div className="platform-options">
                    {onlinePlatforms.map((platform) => (
                      <button
                        key={platform}
                        type="button"
                        className={formData.platforms.online.includes(platform) ? 'active' : ''}
                        onClick={() => handlePlatformChange('online', platform)}
                      >
                        {formatPlatformName(platform)}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button type="submit" className="btn-next">Next</button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default EventForm;