import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SideBar.css';

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.userEmail;

  const [activeTab, setActiveTab] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleEventListNavigation = () => {
    setActiveTab('eventlist');
    navigate('/eventlist', { state: { userEmail } });
    setIsSidebarOpen(false); // Close sidebar on navigation
  };

  const handleProfileNavigation = () => {
    setActiveTab('profile');
    navigate('/profile', { state: { userEmail } });
    setIsSidebarOpen(false); // Close sidebar on navigation
  };

  const handleDashboardNavigation = () => {
    setActiveTab('dashboard');
    navigate('/dashboard', { state: { userEmail } });
    setIsSidebarOpen(false); // Close sidebar on navigation
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </div>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <nav>
          <ul className="menu-items">
            <li className={activeTab === 'dashboard' ? 'active' : ''}>
              <a onClick={handleDashboardNavigation}>
                <i className="icon-dashboard fas fa-tachometer-alt"></i> Dashboard
              </a>
            </li>
            <li className={activeTab === 'eventlist' ? 'active' : ''}>
              <a onClick={handleEventListNavigation}>
                <i className="icon-event-list fas fa-calendar-alt"></i> Event Lists
              </a>
            </li>
          </ul>
        </nav>
        <div className="sidebar-footer">
          <ul>
            <li className={activeTab === 'profile' ? 'active' : ''}>
              <a onClick={handleProfileNavigation}>
                <i className="fas fa-cog"></i> Settings
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fas fa-sign-out-alt"></i> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
