import { useState, useEffect } from 'react';

export const useGlobalUserState = () => {
  const [userEmail, setUserEmail] = useState(() => {
    // Initialize from localStorage
    return localStorage.getItem('userEmail') || '';
  });

  useEffect(() => {
    // Update localStorage when userEmail changes
    localStorage.setItem('userEmail', userEmail);
  }, [userEmail]);

  return [userEmail, setUserEmail];
};