import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './SideBar';
import './EventList.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ViewEventPopup from './ViewEventPopup';

const EventList = () => {
  const location = useLocation();
  const userEmail = location.state?.userEmail;
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      localStorage.removeItem('eventFormData');
      const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_details_info/${userEmail}`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleCreateEvent = () => {
    navigate('/create-event', { state: { userEmail } });
  };

  const handleEdit = async (event) => {
    try {
      navigate('/create-event', { 
        state: { 
          eventName: event.event_name,
          userEmail: userEmail
        } 
      });
    } catch (error) {
      console.error('Error fetching event data:', error);
    }
  };

  const handleView = (event) => {
    setSelectedEvent(event);
    setShowViewPopup(true);
  };

  const handleDeleteConfirmation = (event) => {
    setEventToDelete(event);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://budget-tool-hed-1.onrender.com/delete_event/${eventToDelete.event_name}`);
      setEvents(events.filter((e) => e.event_name !== eventToDelete.event_name));
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  return (
    <div className="event-list-container">
      <Sidebar />
      <main className="main-content">
        <div className="event-header">
          <h3>My Events</h3>
          <button className="create-event-btn" onClick={handleCreateEvent}>Create New Event</button>
        </div>
        <table className="event-table">
          <thead>
            <tr>
              <th>Event Name</th>
              <th>Event Location</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index}>
                <td>{event.event_name}</td>
                <td>{event.event_location}</td>
                <td className="actions-cell">
                  <button onClick={() => handleView(event)} className="action-btn view-btn">
                    <i className="fas fa-eye"></i>
                  </button>
                  <button onClick={() => handleEdit(event)} className="action-btn edit-btn">
                    <i className="fas fa-edit"></i>
                  </button>
                  <button onClick={() => handleDeleteConfirmation(event)} className="action-btn delete-btn">
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {showViewPopup && (
          <ViewEventPopup
            event={selectedEvent}
            onClose={() => setShowViewPopup(false)}
          />
        )}
        {showDeleteConfirmation && (
          <div className="delete-confirmation-popup">
            <div className="delete-confirmation-content">
              <p>Are you sure you want to delete this event?</p>
              <div className="delete-confirmation-buttons">
                <button onClick={handleDelete} className="confirm-delete-btn">Yes</button>
                <button onClick={() => setShowDeleteConfirmation(false)} className="cancel-delete-btn">No</button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default EventList;