import React from 'react';
import './UniversalHeader.css';
import logo from '../Half-Eaten-Donut.png'; 

const UniversalHeader = () => {
    return (
        <header className="universal-header">
            <div className="header-left">
                <a href="/" className="header-logo">
                    <img src={logo} className="logo"/>
                </a>
            </div>
            
            {/* <div className="header-right">
                <div className="header-user">
                </div>
            </div> */}
        </header>
    );
};

export default UniversalHeader;
