// // import React from 'react';
// // import styles from './App.css';
// // import { BrowserRouter as Router, Route, Routes,useLocation,Navigate } from 'react-router-dom';
// // import EventList from './components/MainPage/EventList';
// // import EventForm from './components/CreateEventPage/EventForm';
// // import SideBar from './components/MainPage/SideBar';
// // import EventTable from './components/CreateEventPage/EventTable';
// // import EventDetails from './components/CreateEventPage/EventDetails';
// // import UniversalHeader from './components/UniversalHeader';
// // import Login from './components/LoginPage/Login';

// // function AppContent() {


// //   return (
// //     <div className={styles.app}>
// //       <UniversalHeader/>
// //       <Routes>

// //         <Route path="/" element={<Login />} />
// //         <Route path="/eventlist" element={<EventList />} />

// //         <Route path="*" element={
// //           <div className={styles.contentWrapper}>
// //             <SideBar />
// //             <main className={styles.mainContent}>
// //               <Routes>
// //               <Route path="/create-event" element={<EventForm />} />
// //               <Route path="/eventtable" element={<EventTable />} />
// //               <Route path="/event-details" element={<EventDetails />} />
// //               {/* <Route path="/leads" element={<LeadManagement />} /> */}
              
// //               </Routes>
// //             </main>
// //           </div>
// //         } />
// //       </Routes>
// //     </div>
// //   );
// // }

// // function App() {
// //   return (
// //     <Router>
// //       <AppContent />
// //     </Router>
// //   );
// // }

// // export default App;

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
// import styles from './App.css';
// import EventList from './components/MainPage/EventList';
// import EventForm from './components/CreateEventPage/EventForm';
// import SideBar from './components/MainPage/SideBar';
// import EventTable from './components/CreateEventPage/EventTable';
// import EventDetails from './components/CreateEventPage/EventDetails';
// import UniversalHeader from './components/UniversalHeader';
// import Login from './components/LoginPage/Login';
// import ProfileForm from './components/ProfilePage/ProfileForm';
// import Dashboard from './components/MainPage/DashBoard';

// function AppContent() {
//   const location = useLocation();
//   const showHeader = location.pathname !== '/';

//   return (
//     <div className={styles.app}>
//       {showHeader && <UniversalHeader />}
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="/eventlist" element={<EventList />} />
//         <Route path="*" element={
//           <div className={styles.contentWrapper}>
//             <SideBar />
//             <main className={styles.mainContent}>
//               <Routes>
//               <Route path="/dashboard" element={<Dashboard />} />
//                 <Route path="/create-event" element={<EventForm />} />
//                 <Route path="/eventtable" element={<EventTable />} />
//                 <Route path="/event-details" element={<EventDetails />} />
//                 <Route path="/profile" element={<ProfileForm />} />
//               </Routes>
//             </main>
//           </div>
//         } />
//       </Routes>
//     </div>
//   );
// }

// function App() {
//   return (
//     <Router>
//       <AppContent />
//     </Router>
//   );
// }

// export default App;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styles from './App.css';
import EventList from './components/MainPage/EventList';
import EventForm from './components/CreateEventPage/EventForm';
import SideBar from './components/MainPage/SideBar';
import EventTable from './components/CreateEventPage/EventTable';
import EventDetails from './components/CreateEventPage/EventDetails';
import UniversalHeader from './components/UniversalHeader';
import Login from './components/LoginPage/Login';
import ProfileForm from './components/ProfilePage/ProfileForm';
import Dashboard from './components/MainPage/DashBoard';

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const showHeader = location.pathname !== '/';

  useEffect(() => {
    // Save the current path to localStorage whenever it changes
    localStorage.setItem('lastPath', location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // On initial load, check if there's a saved path
    const lastPath = localStorage.getItem('lastPath');
    if (lastPath && lastPath !== location.pathname) {
      navigate(lastPath);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className={styles.app}>
      {showHeader && <UniversalHeader />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/eventlist" element={<EventList />} />
        <Route path="*" element={
          <div className={styles.contentWrapper}>
            <SideBar />
            <main className={styles.mainContent}>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-event" element={<EventForm />} />
                <Route path="/eventtable" element={<EventTable />} />
                <Route path="/event-details" element={<EventDetails />} />
                <Route path="/profile" element={<ProfileForm />} />
              </Routes>
            </main>
          </div>
        } />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
