// // // import React, { useState, useEffect } from 'react';
// // // import axios from 'axios';
// // // import { useLocation, useNavigate } from 'react-router-dom';
// // // import './EventTable.css';

// // // const EventTable = () => {
// // //   const location = useLocation();
// // //   const navigate = useNavigate();
// // //   const { eventName } = location.state || {};
// // //   const userEmail = location.state?.userEmail;

// // //   const [offlinePlatforms, setOfflinePlatforms] = useState([]);
// // //   const [onlinePlatforms, setOnlinePlatforms] = useState([]);
// // //   const [offlineInvestments, setOfflineInvestments] = useState({});
// // //   const [onlineInvestments, setOnlineInvestments] = useState({});
// // //   const [totalInvestmentOffline, setTotalInvestmentOffline] = useState(0);
// // //   const [totalInvestmentOnline, setTotalInvestmentOnline] = useState(0);

// // //   useEffect(() => {
// // //     if (eventName) {
// // //       fetchPlatforms(eventName);
// // //       fetchInvestmentonline(eventName);
// // //       fetchInvestmentoffline(eventName);
// // //     }
// // //   }, [eventName]);

// // //   const fetchPlatforms = async (eventName) => {
// // //     try {
// // //       const response = await axios.get(`http://localhost:5000/event/${eventName}/${userEmail}`);
// // //       setOfflinePlatforms(response.data.offline);
// // //       setOnlinePlatforms(response.data.online);
// // //     } catch (error) {
// // //       console.error('Error fetching platforms:', error);
// // //     }
// // //   };
// // //   const fetchInvestmentonline = async (eventName) => {
// // //     try {
// // //       const response = await axios.get(`http://localhost:5000/event_investment_online/${eventName}`);
// // //       const onlineData = response.data[0]; // Assuming online data is the first object in the response array
// // //       const filteredOnlineData = {
// // //         digital_service: onlineData.digital_service,
// // //         digital_ad_spend: onlineData.digital_ad_spend,
// // //         sms: onlineData.sms,
// // //         referrals: onlineData.referrals,
// // //         partner_digital: onlineData.partner_digital,
// // //         internal_edms: onlineData.internal_edms,
// // //       };
// // //       setOnlineInvestments(filteredOnlineData);
// // //     } catch (error) {
// // //       console.error('Error fetching investments:', error);
// // //     }
// // //   };

// // //   const fetchInvestmentoffline = async (eventName) => {
// // //     try {
// // //       const response = await axios.get(`http://localhost:5000/event_investment_offline/${eventName}`);
// // //       const offlineData = response.data[0]; // Assuming online data is the first object in the response array
// // //       const filteredOfflineData = {
// // //         posters: offlineData.posters,
// // //         radio: offlineData.radio,
// // //         flyers: offlineData.flyers,
// // //         print_ads: offlineData.print_ads,
// // //         bus_sides: offlineData.bus_sides,
// // //         taxi_backs: offlineData.taxi_backs,
// // //         direct_mail: offlineData.direct_mail,
// // //         pr: offlineData.pr,
// // //         billboard: offlineData.billboard,
// // //         newspaper_ad: offlineData.newspaper_ad,
// // //       };
// // //       setOfflineInvestments(filteredOfflineData);
// // //     } catch (error) {
// // //       console.error('Error fetching investments:', error);
// // //     }
// // //   };

// // //   const handleOfflineInvestmentChange = (platform, value) => {
// // //     setOfflineInvestments(prev => ({
// // //       ...prev,
// // //       [platform]: value
// // //     }));
// // //   };

// // //   const handleOnlineInvestmentChange = (platform, value) => {
// // //     setOnlineInvestments(prev => ({
// // //       ...prev,
// // //       [platform]: value
// // //     }));
// // //   };

// // //   useEffect(() => {
// // //     const totalOffline = offlinePlatforms.reduce((sum, platform) => sum + Number(offlineInvestments[platform] || 0), 0);
// // //     const totalOnline = onlinePlatforms.reduce((sum, platform) => sum + Number(onlineInvestments[platform] || 0), 0);

// // //     setTotalInvestmentOffline(totalOffline);
// // //     setTotalInvestmentOnline(totalOnline);
// // //   }, [offlineInvestments, onlineInvestments, offlinePlatforms, onlinePlatforms]);

// // //   const handleSave = async () => {
// // //     try {
// // //       await axios.post('http://localhost:5000/save_event_offline', {
// // //         eventName,
// // //         offlineInvestments,
// // //         onlineInvestments,
// // //         totalInvestmentOffline,
// // //         totalInvestmentOnline
// // //       });
// // //       await axios.post('http://localhost:5000/save_event_online', {
// // //         eventName,
// // //         offlineInvestments,
// // //         onlineInvestments,
// // //         totalInvestmentOffline,
// // //         totalInvestmentOnline
// // //       });
// // //       navigate(`/event-details`, { state: { eventName,userEmail } });
// // //     } catch (error) {
// // //       console.error('Error saving event:', error);
// // //       alert('Failed to save event');
// // //     }
// // //   };
// // //   const handleBack = () => {
// // //     navigate('/create-event', {
// // //       state: {
// // //         eventName,
// // //         userEmail,
        
// // //       }
// // //     });
// // //   };

// // //   return (
// // //     <div className="create-new-event">
// // //       {/* <h1><span>Event Lists</span> &gt; <span>Create New Event</span></h1> */}
// // //       <h2>Budget Allocation Planned (Optional)</h2>
// // //       <p>If you have already planned out the budgets for each platform, please fill them in here to receive a detailed strategy</p>
      
// // //       <div className="platforms-container">
// // //         <div className="offline-platforms">
// // //           <h3>Offline Platforms</h3>
// // //           <table>
// // //             <thead>
// // //               <tr>
// // //                 <th>Platforms</th>
// // //                 <th>Investments</th>
// // //               </tr>
// // //             </thead>
// // //             <tbody>
// // //               {offlinePlatforms.map(platform => (
// // //                 <tr key={platform}>
// // //                   <td>{platform}</td>
// // //                   <td>
// // //                     <input
// // //                       type="number"
// // //                       value={offlineInvestments[platform] || ''}
// // //                       onChange={(e) => handleOfflineInvestmentChange(platform, e.target.value)}
// // //                     />
// // //                   </td>
// // //                 </tr>
// // //               ))}
// // //             </tbody>
// // //           </table>
// // //         </div>
        
// // //         <div className="online-platforms">
// // //           <h3>Online Platforms</h3>
// // //           <table>
// // //             <thead>
// // //               <tr>
// // //                 <th>Platforms</th>
// // //                 <th>Investments</th>
// // //               </tr>
// // //             </thead>
// // //             <tbody>
// // //               {onlinePlatforms.map(platform => (
// // //                 <tr key={platform}>
// // //                   <td>{platform}</td>
// // //                   <td>
// // //                     <input
// // //                       type="number"
// // //                       value={onlineInvestments[platform] || ''}
// // //                       onChange={(e) => handleOnlineInvestmentChange(platform, e.target.value)}
// // //                     />
// // //                   </td>
// // //                 </tr>
// // //               ))}
// // //             </tbody>
// // //           </table>
// // //         </div>
// // //       </div>
      
// // //       <div className="total-investment">
// // //         <strong>Total Investment Offline: ${totalInvestmentOffline}</strong>
// // //         <br />
// // //         <strong>Total Investment Online: ${totalInvestmentOnline}</strong>
// // //       </div>
      
// // //       <div className="buttons">
// // //         <button onClick={handleBack}>Back</button>
// // //         <button onClick={handleSave}>Next</button>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default EventTable;

// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import './EventTable.css';

// // const EventTable = () => {
// //   const location = useLocation();
// //   const navigate = useNavigate();
// //   const { eventName } = location.state || {};
// //   const userEmail = location.state?.userEmail;

// //   const [offlinePlatforms, setOfflinePlatforms] = useState([]);
// //   const [onlinePlatforms, setOnlinePlatforms] = useState([]);
// //   const [offlineInvestments, setOfflineInvestments] = useState({});
// //   const [onlineInvestments, setOnlineInvestments] = useState({});
// //   const [totalInvestmentOffline, setTotalInvestmentOffline] = useState(0);
// //   const [totalInvestmentOnline, setTotalInvestmentOnline] = useState(0);

// //   useEffect(() => {
// //     if (eventName) {
// //       fetchPlatforms(eventName);
// //       fetchInvestmentonline(eventName);
// //       fetchInvestmentoffline(eventName);
// //     }
// //   }, [eventName]);

// //   const fetchPlatforms = async (eventName) => {
// //     try {
// //       const response = await axios.get(`http://localhost:5000/event/${eventName}/${userEmail}`);
// //       setOfflinePlatforms(response.data.offline);
// //       setOnlinePlatforms(response.data.online);
// //     } catch (error) {
// //       console.error('Error fetching platforms:', error);
// //     }
// //   };

// //   const fetchInvestmentonline = async (eventName) => {
// //     try {
// //       const response = await axios.get(`http://localhost:5000/event_investment_online/${eventName}`);
// //       const onlineData = response.data[0]; 
// //       const filteredOnlineData = Object.keys(onlineData)
// //         .filter(key => onlineData[key] >0) // Filter out zero values
// //         .reduce((obj, key) => {
// //           obj[key] = onlineData[key];
// //           return obj;
// //         }, {});
      
// //       setOnlineInvestments(filteredOnlineData);
// //       setOnlinePlatforms(Object.keys(filteredOnlineData)); // Update the platforms to only include non-zero entries
// //     } catch (error) {
// //       console.error('Error fetching online investments:', error);
// //     }
// //   };

// //   const fetchInvestmentoffline = async (eventName) => {
// //     try {
// //       const response = await axios.get(`http://localhost:5000/event_investment_offline/${eventName}`);
// //       const offlineData = response.data[0];
// //       const filteredOfflineData = Object.keys(offlineData)
// //         .filter(key => offlineData[key] > 0) // Filter out zero values
// //         .reduce((obj, key) => {
// //           obj[key] = offlineData[key];
// //           return obj;
// //         }, {});
      
// //       setOfflineInvestments(filteredOfflineData);
// //       setOfflinePlatforms(Object.keys(filteredOfflineData)); // Update the platforms to only include non-zero entries
// //     } catch (error) {
// //       console.error('Error fetching offline investments:', error);
// //     }
// //   };

// //   const handleOfflineInvestmentChange = (platform, value) => {
// //     setOfflineInvestments(prev => ({
// //       ...prev,
// //       [platform]: value
// //     }));
// //   };

// //   const handleOnlineInvestmentChange = (platform, value) => {
// //     setOnlineInvestments(prev => ({
// //       ...prev,
// //       [platform]: value
// //     }));
// //   };

// //   useEffect(() => {
// //     const totalOffline = offlinePlatforms.reduce((sum, platform) => sum + Number(offlineInvestments[platform] || 0), 0);
// //     const totalOnline = onlinePlatforms.reduce((sum, platform) => sum + Number(onlineInvestments[platform] || 0), 0);

// //     setTotalInvestmentOffline(totalOffline);
// //     setTotalInvestmentOnline(totalOnline);
// //   }, [offlineInvestments, onlineInvestments, offlinePlatforms, onlinePlatforms]);

// //   const handleSave = async () => {
// //     try {
// //       await axios.post('http://localhost:5000/save_event_offline', {
// //         eventName,
// //         offlineInvestments,
// //         onlineInvestments,
// //         totalInvestmentOffline,
// //         totalInvestmentOnline
// //       });
// //       await axios.post('http://localhost:5000/save_event_online', {
// //         eventName,
// //         offlineInvestments,
// //         onlineInvestments,
// //         totalInvestmentOffline,
// //         totalInvestmentOnline
// //       });
// //       navigate(`/event-details`, { state: { eventName, userEmail } });
// //     } catch (error) {
// //       console.error('Error saving event:', error);
// //       alert('Failed to save event');
// //     }
// //   };

// //   const handleBack = () => {
// //     navigate('/create-event', {
// //       state: {
// //         eventName,
// //         userEmail,
// //       }
// //     });
// //   };

// //   return (
// //     <div className="create-new-event">
// //       <h2>Budget Allocation Planned (Optional)</h2>
// //       <p>If you have already planned out the budgets for each platform, please fill them in here to receive a detailed strategy</p>
      
// //       <div className="platforms-container">
// //         <div className="offline-platforms">
// //           <h3>Offline Platforms</h3>
// //           <table>
// //             <thead>
// //               <tr>
// //                 <th>Platforms</th>
// //                 <th>Investments</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {offlinePlatforms.map(platform => (
// //                 <tr key={platform}>
// //                   <td>{platform}</td>
// //                   <td>
// //                     <input
// //                       type="number"
// //                       value={offlineInvestments[platform] || ''}
// //                       onChange={(e) => handleOfflineInvestmentChange(platform, e.target.value)}
// //                     />
// //                   </td>
// //                 </tr>
// //               ))}
// //             </tbody>
// //           </table>
// //         </div>
        
// //         <div className="online-platforms">
// //           <h3>Online Platforms</h3>
// //           <table>
// //             <thead>
// //               <tr>
// //                 <th>Platforms</th>
// //                 <th>Investments</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {onlinePlatforms.map(platform => (
// //                 <tr key={platform}>
// //                   <td>{platform}</td>
// //                   <td>
// //                     <input
// //                       type="number"
// //                       value={onlineInvestments[platform] || ''}
// //                       onChange={(e) => handleOnlineInvestmentChange(platform, e.target.value)}
// //                     />
// //                   </td>
// //                 </tr>
// //               ))}
// //             </tbody>
// //           </table>
// //         </div>
// //       </div>
      
// //       <div className="total-investment">
// //         <strong>Total Investment Offline: ${totalInvestmentOffline}</strong>
// //         <br />
// //         <strong>Total Investment Online: ${totalInvestmentOnline}</strong>
// //       </div>
      
// //       <div className="buttons">
// //         <button onClick={handleBack}>Back</button>
// //         <button onClick={handleSave}>Next</button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default EventTable;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation, useNavigate } from 'react-router-dom';
// import './EventTable.css';

// const EventTable = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { eventName } = location.state || {};
//   const userEmail = location.state?.userEmail;

//   const [offlinePlatforms, setOfflinePlatforms] = useState([]);
//   const [onlinePlatforms, setOnlinePlatforms] = useState([]);
//   const [offlineInvestments, setOfflineInvestments] = useState({});
//   const [onlineInvestments, setOnlineInvestments] = useState({});
//   const [totalInvestmentOffline, setTotalInvestmentOffline] = useState(0);
//   const [totalInvestmentOnline, setTotalInvestmentOnline] = useState(0);

//   useEffect(() => {
//     if (eventName) {
//       fetchPlatforms(eventName);
//     }
//   }, [eventName]);

//   const fetchPlatforms = async (eventName) => {
//     try {
//       const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event/${eventName}`);
//       setOfflinePlatforms(response.data.offline);
//       setOnlinePlatforms(response.data.online);
//       fetchInvestmentonline(eventName, response.data.online);
//       fetchInvestmentoffline(eventName, response.data.offline);
//     } catch (error) {
//       console.error('Error fetching platforms:', error);
//     }
//   };

//   const fetchInvestmentonline = async (eventName, onlinePlatforms) => {
//     try {
//       const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_online/${eventName}`);
//       const onlineData = response.data[0];

//       // Populate the investments into the table based on available platforms
//       const filteredOnlineData = {};
//       onlinePlatforms.forEach(platform => {
//         if (onlineData[platform.toLowerCase()] > 0) {
//           filteredOnlineData[platform] = onlineData[platform.toLowerCase()];
//         }
//       });
//       setOnlineInvestments(filteredOnlineData);
//     } catch (error) {
//       console.error('Error fetching online investments:', error);
//     }
//   };

//   const fetchInvestmentoffline = async (eventName, offlinePlatforms) => {
//     try {
//       const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_offline/${eventName}`);
//       const offlineData = response.data[0];

//       // Populate the investments into the table based on available platforms
//       const filteredOfflineData = {};
//       offlinePlatforms.forEach(platform => {
//         if (offlineData[platform.toLowerCase().replace(' ', '_')] > 0) {
//           filteredOfflineData[platform] = offlineData[platform.toLowerCase().replace(' ', '_')];
//         }
//       });
//       setOfflineInvestments(filteredOfflineData);
//     } catch (error) {
//       console.error('Error fetching offline investments:', error);
//     }
//   };

//   const handleOfflineInvestmentChange = (platform, value) => {
//     setOfflineInvestments(prev => ({
//       ...prev,
//       [platform]: value
//     }));
//   };

//   const handleOnlineInvestmentChange = (platform, value) => {
//     setOnlineInvestments(prev => ({
//       ...prev,
//       [platform]: value
//     }));
//   };

//   useEffect(() => {
//     const totalOffline = offlinePlatforms.reduce((sum, platform) => sum + Number(offlineInvestments[platform] || 0), 0);
//     const totalOnline = onlinePlatforms.reduce((sum, platform) => sum + Number(onlineInvestments[platform] || 0), 0);

//     setTotalInvestmentOffline(totalOffline);
//     setTotalInvestmentOnline(totalOnline);
//   }, [offlineInvestments, onlineInvestments, offlinePlatforms, onlinePlatforms]);

//   const handleSave = async () => {
//     try {
//       await axios.post(`https://budget-tool-hed-1.onrender.com/save_event_offline/${eventName}`, {
//         eventName,
//         userEmail,
//         offlineInvestments,
//         onlineInvestments,
//         totalInvestmentOffline,
//         totalInvestmentOnline
//       });
//       await axios.post(`https://budget-tool-hed-1.onrender.com/save_event_online/${eventName}`, {
//         eventName,
//         userEmail,
//         offlineInvestments,
//         onlineInvestments,
//         totalInvestmentOffline,
//         totalInvestmentOnline
//       });
//       navigate(`/event-details`, { state: { eventName, userEmail } });
//     } catch (error) {
//       console.error('Error saving event:', error);
//       alert('Failed to save event');
//     }
//   };

//   const handleBack = () => {
//     navigate('/create-event', {
//       state: {
//         eventName,
//         userEmail,
//       }
//     });
//   };

//   return (
//     <div className="create-new-event">
//       <h2>Budget Allocation Planned (Optional)</h2>
//       <p>If you have already planned out the budgets for each platform, please fill them in here to receive a detailed strategy</p>
      
//       <div className="platforms-container">
//         <div className="offline-platforms">
//           <h3>Offline Platforms</h3>
//           <table>
//             <thead>
//               <tr>
//                 <th>Platforms</th>
//                 <th>Investments</th>
//               </tr>
//             </thead>
//             <tbody>
//               {offlinePlatforms.map(platform => (
//                 <tr key={platform}>
//                   <td>{platform}</td>
//                   <td>
//                     <input
//                       type="number"
//                       value={offlineInvestments[platform] || ''}
//                       onChange={(e) => handleOfflineInvestmentChange(platform, e.target.value)}
//                     />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
        
//         <div className="online-platforms">
//           <h3>Online Platforms</h3>
//           <table>
//             <thead>
//               <tr>
//                 <th>Platforms</th>
//                 <th>Investments</th>
//               </tr>
//             </thead>
//             <tbody>
//               {onlinePlatforms.map(platform => (
//                 <tr key={platform}>
//                   <td>{platform}</td>
//                   <td>
//                     <input
//                       type="number"
//                       value={onlineInvestments[platform] || ''}
//                       onChange={(e) => handleOnlineInvestmentChange(platform, e.target.value)}
//                     />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
      
//       <div className="total-investment">
//         <strong>Total Investment Offline: ${totalInvestmentOffline}</strong>
//         <br />
//         <strong>Total Investment Online: ${totalInvestmentOnline}</strong>
//       </div>
      
//       <div className="buttons">
//         <button onClick={handleBack}>Back</button>
//         <button onClick={handleSave}>Next</button>
//       </div>
//     </div>
//   );
// };

// export default EventTable;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './EventTable.css';

const EventTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { eventName } = location.state || {};
  const userEmail = location.state?.userEmail;

  const [offlinePlatforms, setOfflinePlatforms] = useState([]);
  const [onlinePlatforms, setOnlinePlatforms] = useState([]);
  const [offlineInvestments, setOfflineInvestments] = useState({});
  const [onlineInvestments, setOnlineInvestments] = useState({});
  const [totalInvestmentOffline, setTotalInvestmentOffline] = useState(0);
  const [totalInvestmentOnline, setTotalInvestmentOnline] = useState(0);

  const formatPlatformName = (name) => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  };

  const unformatPlatformName = (name) => {
    return name.toLowerCase().replace(/ /g, '_');
  };

  useEffect(() => {
    if (eventName) {
      fetchPlatforms(eventName);
    }
  }, [eventName]);

  const fetchPlatforms = async (eventName) => {
    try {
      const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event/${eventName}`);
      setOfflinePlatforms(response.data.offline.map(formatPlatformName));
      setOnlinePlatforms(response.data.online.map(formatPlatformName));
      fetchInvestmentonline(eventName, response.data.online);
      fetchInvestmentoffline(eventName, response.data.offline);
    } catch (error) {
      console.error('Error fetching platforms:', error);
    }
  };

  const fetchInvestmentonline = async (eventName, onlinePlatforms) => {
    try {
      const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_online/${eventName}`);
      const onlineData = response.data[0];

      const filteredOnlineData = {};
      onlinePlatforms.forEach(platform => {
        const formattedPlatform = formatPlatformName(platform);
        if (onlineData[platform.toLowerCase()] > 0) {
          filteredOnlineData[formattedPlatform] = onlineData[platform.toLowerCase()];
        }
      });
      setOnlineInvestments(filteredOnlineData);
    } catch (error) {
      console.error('Error fetching online investments:', error);
    }
  };

  const fetchInvestmentoffline = async (eventName, offlinePlatforms) => {
    try {
      const response = await axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_offline/${eventName}`);
      const offlineData = response.data[0];

      const filteredOfflineData = {};
      offlinePlatforms.forEach(platform => {
        const formattedPlatform = formatPlatformName(platform);
        if (offlineData[platform.toLowerCase()] > 0) {
          filteredOfflineData[formattedPlatform] = offlineData[platform.toLowerCase()];
        }
      });
      setOfflineInvestments(filteredOfflineData);
    } catch (error) {
      console.error('Error fetching offline investments:', error);
    }
  };

  const handleOfflineInvestmentChange = (platform, value) => {
    setOfflineInvestments(prev => ({
      ...prev,
      [platform]: value
    }));
  };

  const handleOnlineInvestmentChange = (platform, value) => {
    setOnlineInvestments(prev => ({
      ...prev,
      [platform]: value
    }));
  };

  useEffect(() => {
    const totalOffline = offlinePlatforms.reduce((sum, platform) => sum + Number(offlineInvestments[platform] || 0), 0);
    const totalOnline = onlinePlatforms.reduce((sum, platform) => sum + Number(onlineInvestments[platform] || 0), 0);

    setTotalInvestmentOffline(totalOffline);
    setTotalInvestmentOnline(totalOnline);
  }, [offlineInvestments, onlineInvestments, offlinePlatforms, onlinePlatforms]);

  const handleSave = async () => {
    try {
      const unformattedOfflineInvestments = Object.entries(offlineInvestments).reduce((acc, [key, value]) => {
        acc[unformatPlatformName(key)] = value;
        return acc;
      }, {});

      const unformattedOnlineInvestments = Object.entries(onlineInvestments).reduce((acc, [key, value]) => {
        acc[unformatPlatformName(key)] = value;
        return acc;
      }, {});

      await axios.post(`https://budget-tool-hed-1.onrender.com/save_event_offline/${eventName}`, {
        eventName,
        userEmail,
        offlineInvestments: unformattedOfflineInvestments,
        onlineInvestments: unformattedOnlineInvestments,
        totalInvestmentOffline,
        totalInvestmentOnline
      });
      await axios.post(`https://budget-tool-hed-1.onrender.com/save_event_online/${eventName}`, {
        eventName,
        userEmail,
        offlineInvestments: unformattedOfflineInvestments,
        onlineInvestments: unformattedOnlineInvestments,
        totalInvestmentOffline,
        totalInvestmentOnline
      });
      navigate(`/event-details`, { state: { eventName, userEmail } });
    } catch (error) {
      console.error('Error saving event:', error);
      alert('Failed to save event');
    }
  };

  const handleBack = () => {
    navigate('/create-event', {
      state: {
        eventName,
        userEmail,
      }
    });
  };

  return (
    <div className="create-new-event">
      <h2>Budget Allocation Planned (Optional)</h2>
      <p>If you have already planned out the budgets for each platform, please fill them in here to receive a detailed strategy</p>
      
      <div className="platforms-container">
        <div className="offline-platforms">
          <h3>Offline Platforms</h3>
          <table>
            <thead>
              <tr>
                <th>Platforms</th>
                <th>Investments</th>
              </tr>
            </thead>
            <tbody>
              {offlinePlatforms.map(platform => (
                <tr key={platform}>
                  <td>{platform}</td>
                  <td>
                    <input
                      type="number"
                      value={offlineInvestments[platform] || ''}
                      onChange={(e) => handleOfflineInvestmentChange(platform, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        <div className="online-platforms">
          <h3>Online Platforms</h3>
          <table>
            <thead>
              <tr>
                <th>Platforms</th>
                <th>Investments</th>
              </tr>
            </thead>
            <tbody>
              {onlinePlatforms.map(platform => (
                <tr key={platform}>
                  <td>{platform}</td>
                  <td>
                    <input
                      type="number"
                      value={onlineInvestments[platform] || ''}
                      onChange={(e) => handleOnlineInvestmentChange(platform, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      <div className="total-investment">
        <strong>Total Investment Offline: ${totalInvestmentOffline}</strong>
        <br />
        <strong>Total Investment Online: ${totalInvestmentOnline}</strong>
      </div>
      
      <div className="buttons">
        <button onClick={handleBack}>Back</button>
        <button onClick={handleSave}>Next</button>
      </div>
    </div>
  );
};

export default EventTable;