// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import './ProfileForm.css';

// const ProfileForm = () => {
//     const location = useLocation();
//     const userEmail = location.state?.userEmail;
//     const [email, setEmail] = useState('');
//     const [userData, setUserData] = useState({
//         full_name: '',
//         email: '',
//         company_name: '',
//         password: ''
//     });
//     const [inviteEmail, setInviteEmail] = useState('');
//     const [activeTab, setActiveTab] = useState('profile'); // New state for tab switching

//     useEffect(() => {
//         axios.get(`http://localhost:5000/get_user_profile/${userEmail}`)
//             .then(response => {
//                 setUserData(response.data[0]);
//             })
//             .catch(error => {
//                 console.error('There was an error fetching user details!', error);
//             });
//     }, [userEmail]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setUserData(prevData => ({
//             ...prevData,
//             [name]: value
//         }));
//     };

//     const handleInviteEmailChange = (e) => {
//         setInviteEmail(e.target.value);
//     };

//     const handleUpdateProfile = (e) => {
//         e.preventDefault();
//         const { email, ...updateData } = userData;
//         axios.post(`http://localhost:5000/Update_profile/${userEmail}`, updateData)
//             .then(response => {
//                 alert('Profile updated successfully!');
//             })
//             .catch(error => {
//                 console.error('There was an error updating the profile!', error);
//             });
//     };

//     const handleInvite = async () => {
//         try {
//           await axios.post('http://localhost:5000/invite', { email });
//           alert('Invitation sent!');
//         } catch (error) {
//           console.error('Error sending invitation:', error);
//           alert('Failed to send invitation. Please try again.');
//         }
//       };

//     return (
//         <div className="profile-form-container">
//             <div className="side-menu">
//                 <button
//                     className={`side-button ${activeTab === 'profile' ? 'active' : ''}`}
//                     onClick={() => setActiveTab('profile')}
//                 >
//                     Profile
//                 </button>
//                 <button
//                     className={`side-button ${activeTab === 'password' ? 'active' : ''}`}
//                     onClick={() => setActiveTab('password')}
//                 >
//                     Password
//                 </button>
//                 <button
//                     className={`side-button ${activeTab === 'invite' ? 'active' : ''}`}
//                     onClick={() => setActiveTab('invite')}
//                 >
//                     Invite
//                 </button>
//             </div>
//             {activeTab === 'profile' && (
//                 <div className="profile-card">
//                     <h2>Edit Profile</h2>
//                     <form onSubmit={handleUpdateProfile}>
//                         <div className="form-group">
//                             <label>Full Name:</label>
//                             <input
//                                 type="text"
//                                 name="full_name"
//                                 value={userData.full_name}
//                                 onChange={handleChange}
//                                 className="input-field"
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label>Email:</label>
//                             <input
//                                 type="email"
//                                 name="email"
//                                 value={userData.email}
//                                 onChange={handleChange}
//                                 readOnly
//                                 className="input-field"
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label>Company Name:</label>
//                             <input
//                                 type="text"
//                                 name="company_name"
//                                 value={userData.company_name}
//                                 onChange={handleChange}
//                                 className="input-field"
//                             />
//                         </div>
//                         <button type="submit" className="btn-primary">Update Profile</button>
//                     </form>
//                 </div>
//             )}
//             {activeTab === 'password' && (
//                 <div className="password-card">
//                     <h2>Change Password</h2>
//                     <form onSubmit={handleUpdateProfile}>
//                         <div className="form-group">
//                             <label>New Password:</label>
//                             <input
//                                 type="password"
//                                 name="new_password"
//                                 value={userData.password}
//                                 onChange={handleChange}
//                                 className="input-field"
//                             />
//                         </div>
//                         <button type="submit" className="btn-primary">Update Password</button>
//                     </form>
//                 </div>
//             )}
//            {activeTab === 'invite' && (
//     <div className="invite-card">
//         <h1>Invite New User</h1>
//                 <label htmlFor="email">Email:</label>
//                 <input
//                     type="email"
//                     id="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     className="input-field"
//                 />
//                 <button onClick={handleInvite} className="btn-primary">Send Invitation</button>
//             </div>
// )}

//         </div>
//     );
// };

// export default ProfileForm;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './ProfileForm.css';

const ProfileForm = () => {
    const location = useLocation();
    const userEmail = location.state?.userEmail;
    const [userData, setUserData] = useState({
        full_name: '',
        email: '',
        company_name: '',
        password: ''
    });
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [activeTab, setActiveTab] = useState('profile');

    useEffect(() => {
        axios.get(`https://budget-tool-hed-1.onrender.com/get_user_profile/${userEmail}`)
            .then(response => {
                setUserData(response.data[0]);
            })
            .catch(error => {
                console.error('There was an error fetching user details!', error);
            });
    }, [userEmail]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        const { email, ...updateData } = userData;
        axios.post(`https://budget-tool-hed-1.onrender.com/Update_profile/${userEmail}`, updateData)
            .then(response => {
                alert('Profile updated successfully!');
            })
            .catch(error => {
                console.error('There was an error updating the profile!', error);
            });
    };

    const handleUpdatePassword = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        axios.post(`https://budget-tool-hed-1.onrender.com/update_password/${userEmail}`, { newPassword })
            .then(response => {
                alert('Password updated successfully!');
            })
            .catch(error => {
                console.error('There was an error updating the password!', error);
            });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleInvite = async () => {
        try {
            await axios.post('https://budget-tool-hed-1.onrender.com/invite', { email: inviteEmail });
            alert('Invitation sent!');
        } catch (error) {
            console.error('Error sending invitation:', error);
            alert('Failed to send invitation. Please try again.');
        }
    };

    return (

        <div className="profile-form-container">
            <div className="side-menu">
                <button
                    className={`side-button ${activeTab === 'profile' ? 'active' : ''}`}
                    onClick={() => setActiveTab('profile')}
                >
                    <i className="fas fa-user"></i>Profile
                </button>
                <button
                    className={`side-button ${activeTab === 'password' ? 'active' : ''}`}
                    onClick={() => setActiveTab('password')}
                >
                    <i className="fas fa-lock"></i>Password
                </button>
                <button
                    className= {`side-button ${activeTab === 'invite' ? 'active' : ''}`}
                    onClick={() => setActiveTab('invite')}
                >
                    <i className="fas fa-user-plus"></i>Invite
                </button>
            </div>
            {activeTab === 'profile' && (
                <div className="profile-card">
                    <h2>Edit Profile</h2>
                    <form onSubmit={handleUpdateProfile}>
                        <div className="form-group">
                            <label>Full Name:</label>
                            <input
                                type="text"
                                name="full_name"
                                value={userData.full_name}
                                onChange={handleChange}
                                className="input-field"
                            />
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleChange}
                                readOnly
                                className="input-field"
                            />
                        </div>
                        <div className="form-group">
                            <label>Company Name:</label>
                            <input
                                type="text"
                                name="company_name"
                                value={userData.company_name}
                                onChange={handleChange}
                                className="input-field"
                            />
                        </div>
                        <button type="submit" className="btn-primary">Update Profile</button>
                    </form>
                </div>
            )}
            {activeTab === 'password' && (
                <div className="password-card">
                    <h2>Change Password</h2>
                    <form onSubmit={handleUpdatePassword}>
                        <div className="form-group">
                            <label>New Password:</label>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                name="new_password"
                                value={newPassword}
                                onChange={handlePasswordChange}
                                className="input-field"
                            />
                            <button type="button" onClick={togglePasswordVisibility}>
                                {passwordVisible ? "Hide" : "Show"}
                            </button>
                        </div>
                        <div className="form-group">
                            <label>Confirm Password:</label>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                name="confirm_password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                className="input-field"
                            />
                        </div>
                        <button type="submit" className="btn-primary">Update Password</button>
                    </form>
                </div>
            )}
            {activeTab === 'invite' && (
                <div className="invite-card">
                    <h2>Invite New User</h2>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        className="input-field"
                    />
                    <button onClick={handleInvite} className="btn-primary">Send Invitation</button>
                </div>
            )}
        </div>
    );
};

export default ProfileForm;
