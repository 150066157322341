// // import React, { useState, useEffect, useRef } from 'react';
// // import axios from 'axios';
// // import { Pie } from 'react-chartjs-2';
// // import { jsPDF } from 'jspdf';
// // import html2canvas from 'html2canvas';
// // import 'chart.js/auto';
// // import './Popup.css';
// // import { useLocation } from 'react-router-dom';

// // const ViewEventPopup = ({ event, onClose }) => {
// //   const [onlineInvestments, setOnlineInvestments] = useState({});
// //   const [offlineInvestments, setOfflineInvestments] = useState({});
// //   const [eventDetails, setEventDetails] = useState({});
// //   const [metricsDetails, setMetricsDetails] = useState({});
// //   const [isLoading, setIsLoading] = useState(true);
// //   const [error, setError] = useState(null);
// //   const pdfContentRef = useRef(null);
// //   const location = useLocation();
// //   const userEmail = location.state?.userEmail;
// //   const [summary, setSummary] = useState('');

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       setIsLoading(true);
// //       try {
// //         const [onlineResponse, offlineResponse, detailsResponse, metricsResponse, summaryResponse] = await Promise.all([
// //           axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_online/${event.event_name}`),
// //           axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_offline/${event.event_name}`),
// //           axios.get(`https://budget-tool-hed-1.onrender.com/event_info/${event.event_name}`),
// //           axios.get(`https://budget-tool-hed-1.onrender.com/event_metric/${event.event_name}`),
// //           axios.get(`https://budget-tool-hed-1.onrender.com/event_summary/${event.event_name}`)
// //         ]);

// //         const onlineData = onlineResponse.data[0];
// //         const offlineData = offlineResponse.data[0];
// //         const detailsData = detailsResponse.data[0];
// //         const metricsData = metricsResponse.data[0];

// //         setOnlineInvestments(onlineData);
// //         setOfflineInvestments(offlineData);
// //         setEventDetails(detailsData);
// //         setMetricsDetails(metricsData);
// //         setSummary(summaryResponse.data);

// //         setIsLoading(false);
// //       } catch (error) {
// //         console.error('Error fetching investments and details:', error);
// //         setError('Failed to load data');
// //         setIsLoading(false);
// //       }
// //     };

// //     if (event.event_name) {
// //       fetchData();
// //     }
// //   }, [event.event_name, userEmail]);

// //   const getPieData = (investments, title) => {
// //     const labels = Object.keys(investments).filter(key => investments[key] > 0);
// //     const data = labels.map(label => investments[label]);
// //     const colorPalette = [
// //       '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
// //       '#FF9F40', '#FF6384', '#C9CBCF', '#7BC225', '#B97EBB'
// //     ];
// //     return {
// //       labels,
// //       datasets: [{
// //         data,
// //         backgroundColor: labels.map((_, index) => colorPalette[index % colorPalette.length]),
// //       }]
// //     };
// //   };

// //   const onlineData = getPieData(onlineInvestments, 'Online Platforms');
// //   const offlineData = getPieData(offlineInvestments, 'Offline Platforms');

// //   const downloadPDF = async () => {
// //     const content = pdfContentRef.current;
// //     const pdf = new jsPDF('p', 'pt', 'a4');
// //     const pdfWidth = pdf.internal.pageSize.getWidth();
// //     const pdfHeight = pdf.internal.pageSize.getHeight();
// //     const margins = {
// //       top: 40,
// //       bottom: 60,
// //       left: 40,
// //       right: 40
// //     };
// //     const contentWidth = pdfWidth - margins.left - margins.right;
// //     const contentHeight = pdfHeight - margins.top - margins.bottom;

// //     // Add event name to the PDF before rendering the content
// //     pdf.setFontSize(18);
// //     pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });

// //     const canvas = await html2canvas(content, {
// //       scale: 2,
// //       useCORS: true,
// //       logging: false,
// //       windowWidth: contentWidth * 2,
// //       windowHeight: contentHeight * 2
// //     });

// //     const imgData = canvas.toDataURL('image/png');
// //     const imgWidth = contentWidth;
// //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
// //     let position = margins.top + 30; // Start below the event name

// //     while (position < imgHeight) {
// //       pdf.addImage(
// //         imgData, 
// //         'PNG', 
// //         margins.left, 
// //         margins.top - position + 30, // Adjust for the added event name
// //         imgWidth, 
// //         imgHeight
// //       );
// //       position += contentHeight;
// //       if (position < imgHeight) {
// //         pdf.addPage();
// //         // Add event name to each new page
// //         pdf.setFontSize(18);
// //         pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });
// //       }
// //     }

// //     pdf.save(`${event.event_name}_report.pdf`);
// //   };

// //   const renderLegend = (data, investments) => (
// //     <div className="chart-legend">
// //       {data.labels.map((label, index) => (
// //         <div key={label} className="legend-item">
// //           <span 
// //             className="color-box" 
// //             style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
// //           ></span>
// //           <span>{label}: {investments[label]} {eventDetails.currency}</span>
// //         </div>
// //       ))}
// //     </div>
// //   );

// //   if (isLoading) {
// //     return <div className="popup-overlay"><div className="popup-content">Give us a minute. Your report is being generated!</div></div>;
// //   }

// //   if (error) {
// //     return <div className="popup-overlay"><div className="popup-content">Error: {error}</div></div>;
// //   }

// //   return (
// //     <div className="popup-overlay">
// //       <div className="popup-content">
// //         <div ref={pdfContentRef} className="pdf-content">
// //           <p><h2>{eventDetails.event_name}</h2></p>
// //           <p><strong>Location:</strong> {eventDetails.event_location}</p>
// //           <p><strong>Date:</strong> {new Date(eventDetails.event_date).toLocaleDateString()}</p>
// //           <p><strong>Organizer:</strong> {eventDetails.company_name}</p>
// //           <p><strong>Objective:</strong> {eventDetails.event_objective}</p>
// //           <p><strong>Target Revenue:</strong> {eventDetails.target_revenue} {eventDetails.currency}</p>
// //           <p><strong>Average Order Value:</strong> {eventDetails.average_order_value} {eventDetails.currency}</p>
// //           <p><strong>Average Ticket Price:</strong> {eventDetails.average_ticket_price} {eventDetails.currency}</p>
// //           <p><strong>Marketing Budget:</strong> {eventDetails.marketing_budget} {eventDetails.currency}</p>
// //           <p><strong>Hit Target:</strong> {eventDetails.hit_target ? 'Yes' : 'No'}</p>
// //           <p><strong>Timezone:</strong> {eventDetails.timezone}</p>
// //           <p><strong>Online Platforms:</strong> {eventDetails.online_platforms.replace(/[{}"_]/g, ' ').split(',').map(platform => platform.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ')}</p>
// //           <p><strong>Offline Platforms:</strong> {eventDetails.offline_platforms.replace(/[{}"_]/g, ' ').split(',').map(platform => platform.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ')}</p>
// //           <div className="chart-wrapper">
// //             {onlineData.labels.length > 0 && (
// //               <div className="chart-container">
// //                 <h3>Online Platforms</h3>
// //                 <Pie data={onlineData} />
// //                 {renderLegend(onlineData, onlineInvestments)}
// //               </div>
// //             )}
// //             {offlineData.labels.length > 0 && (
// //               <div className="chart-container">
// //                 <h3>Offline Platforms</h3>
// //                 <Pie data={offlineData} />
// //                 {renderLegend(offlineData, offlineInvestments)}
// //               </div>
// //             )}
// //           </div>
// //           <p><strong>Actual Overall Revenue :</strong> {metricsDetails.overallrevenue} {eventDetails.currency} </p>
// //            <p><strong>Actual Average Order Value:</strong> {metricsDetails.averageordervalue} {eventDetails.currency}</p>
// //            <p><strong>Actual Total Registrations:</strong> {metricsDetails.totalregistrations} </p>
// //           <div className="summary-section">
// //             <h3>Event Summary</h3>
// //             <p>{summary}</p>
// //           </div>
// //         </div>
// //         <div className="button-container">
// //           <button onClick={downloadPDF}>Download as PDF</button>
// //           <button onClick={onClose}>Close</button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ViewEventPopup;

// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { Pie } from 'react-chartjs-2';
// import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
// import 'chart.js/auto';
// import './Popup.css';
// import { useLocation } from 'react-router-dom';

// const ViewEventPopup = ({ event, onClose }) => {
//   const [onlineInvestments, setOnlineInvestments] = useState({});
//   const [offlineInvestments, setOfflineInvestments] = useState({});
//   const [eventDetails, setEventDetails] = useState({});
//   const [metricsDetails, setMetricsDetails] = useState({});
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const pdfContentRef = useRef(null);
//   const location = useLocation();
//   const userEmail = location.state?.userEmail;
//   const [summary, setSummary] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       try {
//         const [onlineResponse, offlineResponse, detailsResponse, metricsResponse, summaryResponse] = await Promise.all([
//           axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_online/${event.event_name}`),
//           axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_offline/${event.event_name}`),
//           axios.get(`https://budget-tool-hed-1.onrender.com/event_info/${event.event_name}`),
//           axios.get(`https://budget-tool-hed-1.onrender.com/event_metric/${event.event_name}`),
//           axios.get(`https://budget-tool-hed-1.onrender.com/event_summary/${event.event_name}`)
//         ]);

//         const onlineData = onlineResponse.data[0];
//         const offlineData = offlineResponse.data[0];
//         const detailsData = detailsResponse.data[0];
//         const metricsData = metricsResponse.data[0];

//         setOnlineInvestments(onlineData);
//         setOfflineInvestments(offlineData);
//         setEventDetails(detailsData);
//         setMetricsDetails(metricsData);
//         setSummary(summaryResponse.data);

//         setIsLoading(false);
//       } catch (error) {
//         console.error('Error fetching investments and details:', error);
//         setError('Failed to load data');
//         setIsLoading(false);
//       }
//     };

//     if (event.event_name) {
//       fetchData();
//     }
//   }, [event.event_name, userEmail]);

//   const getPieData = (investments, title) => {
//     const labels = Object.keys(investments).filter(key => investments[key] > 0);
//     const data = labels.map(label => investments[label]);
//     const total = data.reduce((acc, curr) => acc + curr, 0);
//     const percentages = data.map(value => ((value / total) * 100).toFixed(2));
//     const colorPalette = [
//       '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
//       '#FF9F40', '#FF6384', '#C9CBCF', '#7BC225', '#B97EBB'
//     ];
//     return {
//       labels,
//       datasets: [{
//         data,
//         backgroundColor: labels.map((_, index) => colorPalette[index % colorPalette.length]),
//       }],
//       percentages
//     };
//   };

//   const onlineData = getPieData(onlineInvestments, 'Online Platforms');
//   const offlineData = getPieData(offlineInvestments, 'Offline Platforms');

//   const downloadPDF = async () => {
//     const content = pdfContentRef.current;
//     const pdf = new jsPDF('p', 'pt', 'a4');
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = pdf.internal.pageSize.getHeight();
//     const margins = {
//       top: 40,
//       bottom: 60,
//       left: 40,
//       right: 40
//     };
//     const contentWidth = pdfWidth - margins.left - margins.right;
//     const contentHeight = pdfHeight - margins.top - margins.bottom;

//     pdf.setFontSize(18);
//     pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });

//     const canvas = await html2canvas(content, {
//       scale: 2,
//       useCORS: true,
//       logging: false,
//       windowWidth: contentWidth * 2,
//       windowHeight: contentHeight * 2
//     });

//     const imgData = canvas.toDataURL('image/png');
//     const imgWidth = contentWidth;
//     const imgHeight = (canvas.height * imgWidth) / canvas.width;
//     let position = margins.top + 30;

//     while (position < imgHeight) {
//       pdf.addImage(
//         imgData, 
//         'PNG', 
//         margins.left, 
//         margins.top - position + 30,
//         imgWidth, 
//         imgHeight
//       );
//       position += contentHeight;
//       if (position < imgHeight) {
//         pdf.addPage();
//         pdf.setFontSize(18);
//         pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });
//       }
//     }

//     pdf.save(`${event.event_name}_report.pdf`);
//   };

//   const renderLegend = (data, investments) => (
//     <div className="chart-legend">
//       {data.labels.map((label, index) => (
//         <div key={label} className="legend-item">
//           <span 
//             className="color-box" 
//             style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
//           ></span>
//           <span>{label}: {investments[label]} {eventDetails.currency} ({data.percentages[index]}%)</span>
//         </div>
//       ))}
//     </div>
//   );

//   const pieOptions = {
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: function(context) {
//             const label = context.label || '';
//             const value = context.parsed || 0;
//             const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
//             const percentage = ((value / total) * 100).toFixed(2);
//             return `${label}: ${value} ${eventDetails.currency} (${percentage}%)`;
//           }
//         }
//       }
//     }
//   };

//   if (isLoading) {
//     return <div className="popup-overlay"><div className="popup-content">Give us a minute. Your report is being generated!</div></div>;
//   }

//   if (error) {
//     return <div className="popup-overlay"><div className="popup-content">Error: {error}</div></div>;
//   }

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div ref={pdfContentRef} className="pdf-content">
//           <p><h2>{eventDetails.event_name}</h2></p>
//           <p><strong>Location:</strong> {eventDetails.event_location}</p>
//           <p><strong>Date:</strong> {new Date(eventDetails.event_date).toLocaleDateString()}</p>
//           <p><strong>Organizer:</strong> {eventDetails.company_name}</p>
//           <p><strong>Objective:</strong> {eventDetails.event_objective}</p>
//           <p><strong>Target Revenue:</strong> {eventDetails.target_revenue} {eventDetails.currency}</p>
//           <p><strong>Average Order Value:</strong> {eventDetails.average_order_value} {eventDetails.currency}</p>
//           <p><strong>Average Ticket Price:</strong> {eventDetails.average_ticket_price} {eventDetails.currency}</p>
//           <p><strong>Marketing Budget:</strong> {eventDetails.marketing_budget} {eventDetails.currency}</p>
//           <p><strong>Hit Target:</strong> {eventDetails.hit_target ? 'Yes' : 'No'}</p>
//           <p><strong>Timezone:</strong> {eventDetails.timezone}</p>
//           <p><strong>Online Platforms:</strong> {eventDetails.online_platforms.replace(/[{}"_]/g, ' ').split(',').map(platform => platform.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ')}</p>
//           <p><strong>Offline Platforms:</strong> {eventDetails.offline_platforms.replace(/[{}"_]/g, ' ').split(',').map(platform => platform.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ')}</p>
//           <div className="chart-wrapper">
//             {onlineData.labels.length > 0 && (
//               <div className="chart-container">
//                 <h3>Online Platforms</h3>
//                 <Pie data={onlineData} options={pieOptions} />
//                 {renderLegend(onlineData, onlineInvestments)}
//               </div>
//             )}
//             {offlineData.labels.length > 0 && (
//               <div className="chart-container">
//                 <h3>Offline Platforms</h3>
//                 <Pie data={offlineData} options={pieOptions} />
//                 {renderLegend(offlineData, offlineInvestments)}
//               </div>
//             )}
//           </div>
//           <p><strong>Actual Overall Revenue :</strong> {metricsDetails.overallrevenue} {eventDetails.currency} </p>
//            <p><strong>Actual Average Order Value:</strong> {metricsDetails.averageordervalue} {eventDetails.currency}</p>
//            <p><strong>Actual Total Registrations:</strong> {metricsDetails.totalregistrations} </p>
//           <div className="summary-section">
//             <h3>Event Summary</h3>
//             <p>{summary}</p>
//           </div>
//         </div>
//         <div className="button-container">
//           <button onClick={downloadPDF}>Download as PDF</button>
//           <button onClick={onClose}>Close</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ViewEventPopup;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'chart.js/auto';
import './Popup.css';
import { useLocation } from 'react-router-dom';

const ViewEventPopup = ({ event, onClose }) => {
  const [onlineInvestments, setOnlineInvestments] = useState({});
  const [offlineInvestments, setOfflineInvestments] = useState({});
  const [eventDetails, setEventDetails] = useState({});
  const [metricsDetails, setMetricsDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const pdfContentRef = useRef(null);
  const location = useLocation();
  const userEmail = location.state?.userEmail;
  const [summary, setSummary] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [onlineResponse, offlineResponse, detailsResponse, metricsResponse, summaryResponse] = await Promise.all([
          axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_online/${event.event_name}`),
          axios.get(`https://budget-tool-hed-1.onrender.com/event_investment_offline/${event.event_name}`),
          axios.get(`https://budget-tool-hed-1.onrender.com/event_info/${event.event_name}`),
          axios.get(`https://budget-tool-hed-1.onrender.com/event_metric/${event.event_name}`),
          axios.get(`https://budget-tool-hed-1.onrender.com/event_summary/${event.event_name}`)
        ]);

        const onlineData = onlineResponse.data[0];
        const offlineData = offlineResponse.data[0];
        const detailsData = detailsResponse.data[0];
        const metricsData = metricsResponse.data[0];

        setOnlineInvestments(onlineData);
        setOfflineInvestments(offlineData);
        setEventDetails(detailsData);
        setMetricsDetails(metricsData);
        setSummary(summaryResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching investments and details:', error);
        setError('Failed to load data');
        setIsLoading(false);
      }
    };

    if (event.event_name) {
      fetchData();
    }
  }, [event.event_name, userEmail]);

  const getPieData = (investments, title) => {
    const labels = Object.keys(investments).filter(key => investments[key] > 0);
    const data = labels.map(label => investments[label]);
    const total = data.reduce((acc, curr) => acc + curr, 0);
    const percentages = data.map(value => ((value / total) * 100).toFixed(2));
    const colorPalette = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
      '#FF9F40', '#FF6384', '#C9CBCF', '#7BC225', '#B97EBB'
    ];
    return {
      labels,
      datasets: [{
        data,
        backgroundColor: labels.map((_, index) => colorPalette[index % colorPalette.length]),
      }],
      percentages
    };
  };

  const onlineData = getPieData(onlineInvestments, 'Online Platforms');
  const offlineData = getPieData(offlineInvestments, 'Offline Platforms');

  const downloadPDF = async () => {
    const content = pdfContentRef.current;
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margins = {
      top: 40,
      bottom: 60,
      left: 40,
      right: 40
    };
    const contentWidth = pdfWidth - margins.left - margins.right;
    const contentHeight = pdfHeight - margins.top - margins.bottom;

    // First page content
    const firstPageContent = content.querySelector('.first-page-content');
    const firstPageCanvas = await html2canvas(firstPageContent, {
      scale: 2,
      useCORS: true,
      logging: false,
      windowWidth: contentWidth * 2,
      windowHeight: contentHeight * 2
    });

    const firstPageImgData = firstPageCanvas.toDataURL('image/png');
    const firstPageImgWidth = contentWidth;
    const firstPageImgHeight = (firstPageCanvas.height * firstPageImgWidth) / firstPageCanvas.width;

    pdf.setFontSize(18);
    pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });
    pdf.addImage(
      firstPageImgData,
      'PNG',
      margins.left,
      margins.top + 30,
      firstPageImgWidth,
      firstPageImgHeight
    );

    // Second page content
    pdf.addPage();
    pdf.setFontSize(18);
    pdf.text(event.event_name, pdfWidth / 2, margins.top, { align: 'center' });

    const secondPageContent = content.querySelector('.second-page-content');
    const secondPageCanvas = await html2canvas(secondPageContent, {
      scale: 2,
      useCORS: true,
      logging: false,
      windowWidth: contentWidth * 2,
      windowHeight: contentHeight * 2
    });

    const secondPageImgData = secondPageCanvas.toDataURL('image/png');
    const secondPageImgWidth = contentWidth;
    const secondPageImgHeight = (secondPageCanvas.height * secondPageImgWidth) / secondPageCanvas.width;

    pdf.addImage(
      secondPageImgData,
      'PNG',
      margins.left,
      margins.top + 30,
      secondPageImgWidth,
      secondPageImgHeight
    );

    pdf.save(`${event.event_name}_report.pdf`);
  };

  const renderLegend = (data, investments) => (
    <div className="chart-legend">
      {data.labels.map((label, index) => (
        <div key={label} className="legend-item">
          <span 
            className="color-box" 
            style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
          ></span>
          <span>{label}: {investments[label]} {eventDetails.currency} ({data.percentages[index]}%)</span>
        </div>
      ))}
    </div>
  );

  const pieOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value} ${eventDetails.currency} (${percentage}%)`;
          }
        }
      }
    }
  };

  if (isLoading) {
    return <div className="popup-overlay"><div className="popup-content">Give us a minute. Your report is being generated!</div></div>;
  }

  if (error) {
    return <div className="popup-overlay"><div className="popup-content">Error: {error}</div></div>;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div ref={pdfContentRef} className="pdf-content">
          <div className="first-page-content">
            {/* <p><h2>{eventDetails.event_name}</h2></p> */}
            <p><strong>Location:</strong> {eventDetails.event_location}</p>
            <p><strong>Date:</strong> {new Date(eventDetails.event_date).toLocaleDateString()}</p>
            <p><strong>Organizer:</strong> {eventDetails.company_name}</p>
            <p><strong>Objective:</strong> {eventDetails.event_objective}</p>
            <p><strong>Target Revenue:</strong> {eventDetails.target_revenue} {eventDetails.currency}</p>
            <p><strong>Average Order Value:</strong> {eventDetails.average_order_value} {eventDetails.currency}</p>
            <p><strong>Average Ticket Price:</strong> {eventDetails.average_ticket_price} {eventDetails.currency}</p>
            <p><strong>Marketing Budget:</strong> {eventDetails.marketing_budget} {eventDetails.currency}</p>
            <p><strong>Hit Target:</strong> {eventDetails.hit_target ? 'Yes' : 'No'}</p>
            <p><strong>Timezone:</strong> {eventDetails.timezone}</p>
            <p><strong>Online Platforms:</strong> {eventDetails.online_platforms.replace(/[{}"_]/g, ' ').split(',').map(platform => platform.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ')}</p>
            <p><strong>Offline Platforms:</strong> {eventDetails.offline_platforms.replace(/[{}"_]/g, ' ').split(',').map(platform => platform.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ')}</p>
            <div className="chart-wrapper">
              {onlineData.labels.length > 0 && (
                <div className="chart-container">
                  <h3>Online Platforms</h3>
                  <Pie data={onlineData} options={pieOptions} />
                  {renderLegend(onlineData, onlineInvestments)}
                </div>
              )}
              {offlineData.labels.length > 0 && (
                <div className="chart-container">
                  <h3>Offline Platforms</h3>
                  <Pie data={offlineData} options={pieOptions} />
                  {renderLegend(offlineData, offlineInvestments)}
                </div>
              )}
            </div>
          </div>
          <div className="second-page-content">
            <p><strong>Actual Overall Revenue:</strong> {metricsDetails.overallrevenue} {eventDetails.currency}</p>
            <p><strong>Actual Average Order Value:</strong> {metricsDetails.averageordervalue} {eventDetails.currency}</p>
            <p><strong>Actual Total Registrations:</strong> {metricsDetails.totalregistrations}</p>
            <div className="summary-section">
              <h3>Event Summary</h3>
              <p>{summary}</p>
            </div>
          </div>
        </div>
        <div className="button-container">
          <button onClick={downloadPDF}>Download as PDF</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ViewEventPopup;