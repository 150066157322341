import React from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import Slider from "react-slick";
import "./DashBoard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';


const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state?.userEmail;
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    appendDots: dots => (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)"
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  const handleCreateEvent = () => {
    navigate('/create-event', { state: { userEmail } });
  };

  const handleViewEvents = () => {
    navigate('/eventlist', { state: { userEmail } });
  };

  return (
    <div className="dashboard-container">
      <SideBar />
      <main className="content">
        <header className="header">
          {/* Add search bar or other header elements here */}
        </header>
        <section className="image-slider">
          <Slider {...sliderSettings}>
            <div className="slider-item">
              <img src="/images/2.png" alt="Slider 1" className="slider-image" />
            </div>
            <div className="slider-item">
              <img src="/images/3.png" alt="Slider 2" className="slider-image" />
            </div>
            <div className="slider-item">
              <img src="/images/4.png" alt="Slider 3" className="slider-image" />
            </div>
            <div className="slider-item">
              <img src="/images/ev12.png" alt="Slider 4" className="slider-image" />
            </div>
          </Slider>
        </section>
        <section className="trending-events">
          <div className="trending-cards">
            <div
              className="trending-card"
              style={{
                backgroundImage: "url('/images/LeadHype.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p>Create Your Event</p>
              <button type="button" className="sign-in-button" onClick={handleCreateEvent}>
                Create Event
              </button>
            </div>
            <div
              className="trending-card"
              style={{
                backgroundImage: "url('/images/LeadHype2.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p>Explore Your Events</p>
              <button type="button" className="sign-in-button" onClick={handleViewEvents}>
                View Events
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;